import styles from "../Styles/Sections.module.css";
import stylesOnboarding from "../Styles/Onboarding.module.css";
import { CheckIcon, Cross1Icon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { backendApiAddress } from "../../backendApi";
import { t } from "i18next";
import { SubscriptionType } from "../../Login/LoginState";
import {
  useMeQuery,
  useGetPayedSubscriptionMutation,
} from "../../store/woolitApi";
import mixpanel from "mixpanel-browser";
import i18n from "../../i18n/i18n";

function OnboardingCard(props: {
  title: string;
  description: { includes: string[]; notIncludes: string[] };
  price: string;
  flagged: boolean;
  type: SubscriptionType;
}) {
  const { data: user } = useMeQuery();

  const navigate = useNavigate();

  const [getPayedSubscription] = useGetPayedSubscriptionMutation();

  const getSubscription = async (subscriptionType = props.type) => {
    mixpanel.track("landingPage:subscription", {
      subscriptionType,
    });

    if (!user) {
      navigate("/login", {
        state: {
          subscribe: {
            onboarding: "checkout",
            subscriptionType: props.type,
          },
        },
      });
      return;
    }

    getPayedSubscription({ subscriptionType, language: i18n.language });
  };

  return (
    <div className={`${stylesOnboarding.onboardingCardContainer}`}>
      <div
        className={`${stylesOnboarding.onboardingCard} ${
          props.flagged ? stylesOnboarding.flaggedCard : ""
        } `}
      >
        {/* {props.flagged && (
        <div className={stylesOnboarding.recommendedTag}> Anbefalt</div>
      )} */}
        <div
          style={{ color: "#8F6D57", fontSize: "18px", alignSelf: "center" }}
        >
          Woolit Design Studio Pro
        </div>

        <div
          style={{ fontSize: "31px", paddingBottom: "5%", paddingTop: "2%" }}
        >
          {" "}
          {props.title}
        </div>

        <div className={stylesOnboarding.desciptionField}>
          {props.description.includes.map((description) => {
            return (
              <div className={stylesOnboarding.descriptionRow}>
                <CheckIcon color="green"></CheckIcon>
                <div className={stylesOnboarding.description}>
                  {description}
                </div>
              </div>
            );
          })}

          {props.description.notIncludes.map((description) => {
            return (
              <div className={stylesOnboarding.descriptionRow}>
                <Cross1Icon color="red"></Cross1Icon>
                <div className={stylesOnboarding.description}>
                  {description}
                </div>
              </div>
            );
          })}
        </div>

        <div style={{ paddingTop: "10px" }}>{}</div>
        <div
          style={{
            fontSize: "31px",
            paddingBottom: "0px",
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          {" "}
          {props.price}
          <div style={{ fontSize: "12px" }}>{"/" + t("landing.month")}</div>
        </div>
        {!user?.subscriber && (
          <button
            onClick={() => {
              getSubscription();
            }}
            className={styles.ctaButton}
          >
            {t("landing.sign up")}
          </button>
        )}
        {user?.subscriber && (
          <>
            {user.subscription.subscriptionType === props.type && (
              <a href="/projects" className={styles.ctaButton}>
                {t("goToWDS")}
              </a>
            )}
            {user.subscription.subscriptionType !== props.type &&
              user.subscription.subscriptionType !== "free" &&
              !user.subscription.isTrial && (
                <a
                  className={styles.ctaButton}
                  href={`${backendApiAddress}/api/wds/payment/portal_session`}
                >
                  {t("landing.upgrade")}
                </a>
              )}
            {user.subscription.subscriptionType !== props.type &&
              (user.subscription.subscriptionType === "free" ||
                user.subscription.isTrial) && (
                <button
                  onClick={() => {
                    getSubscription();
                  }}
                  className={styles.ctaButton}
                >
                  {t("landing.sign up")}
                </button>
              )}
          </>
        )}
      </div>
    </div>
  );
}

export default OnboardingCard;
