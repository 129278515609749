import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en_common from "./locales/en/common.json";
import no_common from "./locales/no/common.json";
import de_common from "./locales/de/common.json";

const resources: Resource = {
  en: { common: en_common },
  no: { common: no_common },
  de: { common: de_common },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "no",
    supportedLngs: ["no", "en", "de"],
    ns: ["common"],
    defaultNS: "common",
    interpolation: { escapeValue: false },
  });

export function currencyFromLanguage(language: string) {
  switch (language) {
    case "no":
      return "nok";
    case "en":
      return "usd";
    case "de":
      return "eur";
    default:
      return "nok";
  }
}

export default i18n;
