import {
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { backendApiAddress } from "../backendApi";
import { LocationState } from "../types/location";
import { SubscriptionType, User } from "../Login/LoginState";
import { RegisterUserCredentials } from "../Login/types/auth";
import mixpanel from "mixpanel-browser";
import i18n, { currencyFromLanguage } from "../i18n/i18n";

export const woolitApi = createApi({
  reducerPath: "woolit",
  baseQuery: fetchBaseQuery({
    baseUrl: `${backendApiAddress}/api`,
    credentials: "include",
    headers: {
      "X-Client-Language": i18n.language,
    },
  }),
  tagTypes: [
    "user",
    "ModelKnitAssignment",
    "ModelKnitter",
    "Price",
    "SYMBOLS",
    "ORGANIZATION",
    "PUBLICATION",
    "LIBRARY",
  ],
  endpoints: (builder) => ({
    login: builder.mutation<
      null,
      {
        credentials: { email: string; password: string };
        state?: LocationState;
        language: string;
      }
    >({
      invalidatesTags: ["user"],
      queryFn: async (
        { credentials, state, language },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const result = await fetchWithBQ({
          url: "/auth/login_with_password",
          method: "POST",
          body: {
            email: credentials.email,
            password: credentials.password,
          },
        });

        const response = result.meta?.response;

        if (!response || response.status !== 200) {
          return {
            error: result.error as FetchBaseQueryError,
          };
        }

        let loggedInUrl = null;
        if (response && response.status === 200 && state && state.subscribe) {
          const subscribeState = state.subscribe;

          switch (subscribeState.onboarding) {
            case "free": {
              const result: any = await fetchWithBQ({
                url: "/wds/onboarding/free",
                method: "POST",
              });

              if ("error" in result) {
                break;
              }

              mixpanel.track("subscription:created", {
                subscriptionType: "free",
              });

              loggedInUrl = `/edit/${result.data.patternId}/draw`;
              break;
            }
            case "checkout": {
              const result: any = await fetchWithBQ({
                url: "/wds/payment/checkout_session",
                method: "POST",
                body: {
                  subscriptionType: subscribeState.subscriptionType,
                  locale: language,
                  currency: currencyFromLanguage(language),
                },
              });

              if ("error" in result) {
                break;
              }

              mixpanel.track("subscription:created", {
                subscriptionType: subscribeState.subscriptionType,
              });

              loggedInUrl = result.data.url;
              break;
            }
          }
        }

        if (loggedInUrl) {
          window.location.href = loggedInUrl;
        }

        return { data: null };
      },
    }),
    logout: builder.mutation<null, void>({
      invalidatesTags: ["user"],
      queryFn: async (_args, queryApi, _extraOptions, fetchWithBQ) => {
        await fetchWithBQ({ url: `/logout`, method: "POST" });

        queryApi.dispatch(woolitApi.util.resetApiState());

        return { data: null };
      },
    }),
    register: builder.mutation<
      null,
      {
        credentials: RegisterUserCredentials;
        state?: LocationState;
        language: string;
      }
    >({
      invalidatesTags: ["user"],
      queryFn: async (
        { credentials, state, language },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const result = await fetchWithBQ({
          url: "/auth/register_with_password",
          method: "POST",
          body: credentials,
        });

        const response = result.meta?.response;

        if (!response || response.status !== 200) {
          return {
            error: result.error as FetchBaseQueryError,
          };
        }

        let loggedInUrl = null;
        if (response && response.status === 200 && state && state.subscribe) {
          const subscribeState = state.subscribe;

          switch (subscribeState.onboarding) {
            case "free": {
              const result: any = await fetchWithBQ({
                url: "/wds/onboarding/free",
                method: "POST",
              });

              if ("error" in result) {
                break;
              }

              loggedInUrl = `/edit/${result.data.patternId}/draw`;
              break;
            }
            case "checkout": {
              const result: any = await fetchWithBQ({
                url: "/wds/payment/checkout_session",
                method: "POST",
                body: {
                  subscriptionType: subscribeState.subscriptionType,
                  locale: language,
                  currency: currencyFromLanguage(language),
                },
              });

              if ("error" in result) {
                break;
              }

              loggedInUrl = result.data.url;
              break;
            }
          }
        }

        if (loggedInUrl) {
          window.location.href = loggedInUrl;
        }

        return { data: null };
      },
    }),
    me: builder.query<User, void>({
      query: () => "/pattern/me",
      providesTags: ["user"],
    }),
    setTutorialSeen: builder.mutation<void, "write" | "draw">({
      invalidatesTags: ["user"],
      query: (data) => ({
        url: `/wds/tutorial/${data}`,
        method: "POST",
      }),
    }),
    getFreeSubscription: builder.mutation<{ patternId: number }, void>({
      invalidatesTags: ["user"],
      query: () => ({
        url: "/wds/onboarding/free",
        method: "POST",
      }),
      transformResponse: (response: { patternId: number }) => {
        window.location.href = `/edit/${response.patternId}/draw`;

        mixpanel.track("subscription:created", {
          subscriptionType: "free",
        });

        return response;
      },
    }),

    getPayedSubscription: builder.mutation<
      { url: string },
      { subscriptionType: SubscriptionType; language: string }
    >({
      invalidatesTags: ["user"],
      query: ({ subscriptionType, language }) => ({
        url: "/wds/payment/checkout_session",
        method: "POST",
        body: {
          subscriptionType,
          locale: language,
          currency: currencyFromLanguage(language),
        },
      }),
      transformResponse: (
        response: { url: string },
        _,
        { subscriptionType }
      ) => {
        window.location.replace(response.url);

        mixpanel.track("subscription:created", {
          subscriptionType,
        });

        return response;
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useMeQuery,
  useSetTutorialSeenMutation,
  useGetFreeSubscriptionMutation,
  useGetPayedSubscriptionMutation,
} = woolitApi;
