import React, { useState } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import { LoginUserCredentials, RegisterUserCredentials } from "./types/auth";
import LoginForm from "./components/forms/LoginForm";
import RegisterForm from "./components/forms/RegisterForm";
import Button from "./components/ui/Button/Button";
import styles from "./Login.module.css";
import { LocationState } from "../types/location";
import { useLoginMutation, useRegisterMutation } from "../store/woolitApi";
import i18n from "../i18n/i18n";

const AuthModalContent: React.FC = () => {
  const { t } = useTranslation();

  const [type, setType] = useState<"login" | "register">("login");

  const state = useLocation().state as LocationState;

  const [login] = useLoginMutation();
  const [register] = useRegisterMutation();

  const handleLogin = (loginCredentials: LoginUserCredentials) => {
    login({ credentials: loginCredentials, state, language: i18n.language });
  };

  const handleRegister = (registerCredentials: RegisterUserCredentials) => {
    register({
      credentials: registerCredentials,
      state,
      language: i18n.language,
    });
  };
  return (
    <>
      <div className={styles.innerModal}>
        {type === "login" && (
          <>
            <LoginForm onSubmit={handleLogin} />
            <div
              style={{
                display: "flex",
                marginTop: "2.5rem",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "0.75rem",
              }}
            >
              <p
                style={{
                  padding: "0.5rem",

                  backgroundColor: "#ffffff",
                  fontSize: "20px",
                }}
              >
                {t("noWoolitAccount")}
              </p>
              <Button
                style={{ width: "100%" }}
                variant="secondary"
                onClick={() => setType("register")}
              >
                {t("register")}
              </Button>
            </div>
          </>
        )}
        {type === "register" && (
          <>
            <RegisterForm onSubmit={handleRegister} />
            <div
              style={{
                display: "flex",
                marginTop: "2.5rem",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "0.75rem",
              }}
            >
              <p>{t("haveWoolitAccount")}</p>
              <Button
                style={{ width: "100%" }}
                variant="secondary"
                onClick={() => setType("login")}
              >
                {t("login")}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AuthModalContent;
