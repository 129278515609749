import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Button from "../ISO/Components/Button";
import { toggleMenuDrawer } from "../store/writerSlice";
import ModelKnitAssignmentsTable from "./ModelKnitAssignmentsTable";
import ModelKnitters from "./ModelKnitters";
import styles from "./organization.module.css";
import PriceListTable from "./PriceListTable";
import { hasAccess } from "../utils/hasAccess";
import Members from "./Members";
import Description from "./Description";
import PayoutAgreementDetails from "./PayoutAgreementDetails";
import { useMeQuery } from "../store/woolitApi";

export const Organization: React.FC = () => {
  const { orgId } = useParams();

  const dispatch = useDispatch();

  const { data: user } = useMeQuery();

  const hasModelKnitAccess = hasAccess(user, "model_knit");

  if (!orgId) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div style={{ padding: "20px" }}>
        <Button onClick={() => dispatch(toggleMenuDrawer())}>
          <HamburgerMenuIcon />
        </Button>
      </div>

      {hasModelKnitAccess && (
        <>
          <ModelKnitAssignmentsTable orgId={orgId} />
          <ModelKnitters orgId={orgId} />
          <PriceListTable orgId={orgId} />
          <Members orgId={orgId} />
          <Description orgId={orgId} />
          {user?.isAdmin && <PayoutAgreementDetails orgId={orgId} />}
        </>
      )}
    </div>
  );
};
