import { User } from "../Login/LoginState";
import { woolitApi } from "../store/woolitApi";

export interface Organization {
  id: number;
  name: string;
  slug: string | null;
  description: string | null;
  payout: { yarnCutPercent: number } | null;
}

const woolitApiWithOrganization = woolitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrgs: builder.query<Organization[], void>({
      query: () => "/organizations/mine",
    }),
    getOrganizationById: builder.query<Organization, string>({
      query: (id) => `/organizations/${id}`,
    }),
    getUsers: builder.query<User[], string>({
      query: (id) => `/organizations/${id}/users`,
    }),
    updateOrganization: builder.mutation<
      Organization,
      { id: string; slug?: string | null; description?: string | null }
    >({
      query: (organization) => ({
        url: `/organizations/${organization.id}`,
        method: "PUT",
        body: JSON.stringify({
          description: organization.description,
        }),
      }),
    }),
    setYarnCutPercent: builder.mutation<
      Organization,
      { id: string; yarnCutPercent: number }
    >({
      query: (organization) => ({
        url: `/organizations/${organization.id}/set_yarn_cut_percent/${organization.yarnCutPercent}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetAllOrgsQuery,
  useGetOrganizationByIdQuery,
  useGetUsersQuery,
  useUpdateOrganizationMutation,
  useSetYarnCutPercentMutation,
} = woolitApiWithOrganization;
