import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetOrganizationByIdQuery,
  useSetYarnCutPercentMutation,
} from "../services/organization";
import styles from "./Table.module.css";
import { useModal } from "../CommonComponents/useModal";
import { Modal } from "../CommonComponents/Modal";
import Button from "../ISO/Components/Button";
import { Input } from "@mui/material";

type Props = {
  orgId: string;
};

const PayoutAgreementDetails: React.FC<Props> = ({ orgId }) => {
  const editYarnCutModal = useModal();

  const [setYarnCutPercent] = useSetYarnCutPercentMutation();

  const { t } = useTranslation();

  const orgQuery = useGetOrganizationByIdQuery(orgId, {
    skip: orgId === "-1",
  });

  const [yarnCutPercent, updatedYarnCutPercent] = useState(
    orgQuery.data?.payout?.yarnCutPercent || ""
  );

  useEffect(() => {
    if (orgQuery.data?.payout?.yarnCutPercent) {
      updatedYarnCutPercent(orgQuery.data.payout?.yarnCutPercent);
    }
  }, [orgQuery.data?.payout?.yarnCutPercent]);

  return (
    <div>
      <div className={styles.titleContainer}>
        <h1>Yarn cut</h1>
        <div>
          <button
            style={{ marginRight: "16px" }}
            onClick={() => editYarnCutModal.setIsShown(true)}
          >
            Edit yarn cut
          </button>
          <Modal
            hide={() => editYarnCutModal.setIsShown(false)}
            isShown={editYarnCutModal.isShown}
            modalContent={
              <div style={{ padding: "24px 36px" }}>
                <h1>Edit yarn cut</h1>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Input
                    autoFocus
                    type="number"
                    value={yarnCutPercent || undefined}
                    onChange={(e) => updatedYarnCutPercent(e.target.value)}
                  />
                  <Button
                    active
                    onClick={() => {
                      setYarnCutPercent({
                        id: orgId,
                        yarnCutPercent: Number(yarnCutPercent),
                      });
                      editYarnCutModal.setIsShown(false);
                    }}
                  >
                    {t("navigation.save")}
                  </Button>
                </div>
              </div>
            }
            headerText=""
          />
        </div>
      </div>
      <div style={{ margin: "0 20vw", marginBottom: "100px" }}>
        <p>{yarnCutPercent}</p>
      </div>
    </div>
  );
};

export default PayoutAgreementDetails;
